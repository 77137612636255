body {
  padding: 0;
  margin: 0;
  font-family: sans-serif;
  background: #101010;
  color: #fff;
}

#root {
  position: relative;
  margin: 0 auto;
  max-width: 55rem;
}