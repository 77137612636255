.rainbow-blink {
  animation-name: rainbowBlink;
  animation-duration: .7s;
  animation-iteration-count: infinite;
  .counter, .counter-amount {
    transition: none !important;
  }
  a {
    color: inherit !important;
    transition: none !important;
  }
  .mdi-icon {
    color: inherit;
  }
}

@keyframes rainbowBlink {
  0% {
    color: #ffc401;
  }
  50% {
    color: #f77001;
  }
  100% {
    color: #ffc401;
  }
}