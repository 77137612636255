@mixin md-icon-size($size: 24px) {
  font-size: $size;
  height: $size;
  width: $size;
}

$yellow:#FFDC01;
$orange:#FDAC01;
$red:#F73B01;

.rates {

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));

  .rate {

    a {
      color: #fff;
      text-decoration: none;
    }

    font-size: 2rem;
    margin: 1.5rem;

    h5 {
      font-size: .7rem;
      margin: 0 0 .25rem 0;
      font-weight: normal;
      .numerator {
        font-size: .8em;
        opacity: 80%;
      }
      .delimiter {
        margin: 0 .2em;
        opacity: 80%;
      }
    }

    .counter {
      white-space: nowrap;
      &-suffix {
        font-size: .9em;
        opacity: .8;
      }
      &-prefix {
        opacity: .8;
        font-size: .9em;
        margin-right: .35rem;
        svg {
          vertical-align: -.05rem;
        }
      }
      .decimals, &-postfix {
        opacity: .65;
        font-size: 1.5rem;
        vertical-align: .37rem;
        margin-left: .15rem;
      }
      &-postfix {
      }
    }

    .change {
      font-size: 1.15rem;
      margin-top: .1rem;
      margin-left: 0;
      opacity: .65;
      .decimals, .counter-postfix {
        opacity: 1;
        font-size: inherit;
        vertical-align: 0;
        margin-left: .15rem;
      }
      .mdi-icon {
        @include md-icon-size(1.15rem);
        vertical-align: -.15rem;
      }
    }
    &.is-both-ath {
      .digits, .decimals, .counter-suffix, .counter-prefix {
        background: linear-gradient(to bottom, #fff 0%, lighten(mix($yellow, $orange, 10%), 20%) 50%, lighten($orange, 20%) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .counter-suffix, .counter-prefix {
        opacity: 100%;
      }
      .changing {
        text-shadow: 0 0 .75rem $orange;
      }
      h5 {
        color: mix($yellow, $orange, 80%);
        opacity: .6;
      }
      svg {
        color: mix($yellow, $orange, 80%);
      }
    }
    &.is-nok-ath {
      .digits, .decimals, .counter-suffix, .counter-prefix {
        background: linear-gradient(to bottom, white, gray 50%, gray 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .counter-suffix, .counter-prefix {
        opacity: 100%;
      }
      .changing {
        text-shadow: 0 0 .75rem white;
      }
      h5 {
        color: white;
        opacity: .6;
      }
      svg {
        color: #ccc;
      }
    }
    &.is-usd-ath {
      .digits, .decimals, .counter-suffix, .counter-prefix {
        background: linear-gradient(to bottom, white, #a67130 50%, #a26e2b 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .counter-suffix, .counter-prefix {
        opacity: 100%;
      }
      .changing {
        text-shadow: 0 0 .75rem #f8a439;
      }
      h5 {
        color: #f8a439;
        opacity: .6;
      }
      svg {
        color: #f8a439;
      }
    }
  }
}