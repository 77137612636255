.counter {

  display: inline-block;
  position: relative;
  transition: color .5s ease-in, text-shadow .5s ease-in;

  span {
    display: inline !important;
  }

  &.bold {
    font-weight: bold;
  }

  &.changing {
    border-radius: .2em;
    &.positive {
      color: #90efba !important;
      text-shadow: 0 0 .75rem #2ecc71;
    }
    &.negative {
      color: #ff9f99 !important;
      text-shadow: 0 0 .75rem #e74c3c;
    }
  }
  .counter-amount {
    position: relative;
    transition: color .5s ease-in, text-shadow .5s ease-in;
  }
  &.stable {
    transition: color .5s ease-out, text-shadow .5s ease-out;
  }

}